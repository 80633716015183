<template>
<svg id="Group_4864" data-name="Group 4864" xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 41 41">
  <g id="Group_4851" data-name="Group 4851" transform="translate(0 17.767)">
    <g id="Group_4850" data-name="Group 4850">
      <path id="Path_4573" data-name="Path 4573" d="M25.967,216.269V210.05a2.053,2.053,0,0,0-2.05-2.05H4.783a2.053,2.053,0,0,0-2.05,2.05v6.219A3.423,3.423,0,0,0,0,219.617V230.55a.684.684,0,0,0,.683.683H3.417a.684.684,0,0,0,.683-.683V228.5H24.6v2.05a.684.684,0,0,0,.683.683h2.733a.684.684,0,0,0,.683-.683V219.617A3.423,3.423,0,0,0,25.967,216.269ZM4.1,210.05a.684.684,0,0,1,.683-.683H23.917a.684.684,0,0,1,.683.683v6.15H23.233v-2.733a2.053,2.053,0,0,0-2.05-2.05h-4.1a2.053,2.053,0,0,0-2.05,2.05V216.2H13.667v-2.733a2.053,2.053,0,0,0-2.05-2.05h-4.1a2.053,2.053,0,0,0-2.05,2.05V216.2H4.1Zm17.767,3.417V216.2H16.4v-2.733a.684.684,0,0,1,.683-.683h4.1A.684.684,0,0,1,21.867,213.467Zm-9.567,0V216.2H6.833v-2.733a.684.684,0,0,1,.683-.683h4.1A.684.684,0,0,1,12.3,213.467Zm15.033,16.4H25.967v-2.05a.684.684,0,0,0-.683-.683H3.417a.684.684,0,0,0-.683.683v2.05H1.367V224.4H27.333Zm0-6.833H1.367v-3.417a2.053,2.053,0,0,1,2.05-2.05H25.283a2.053,2.053,0,0,1,2.05,2.05Z" transform="translate(0 -208)" :fill="color"/>
    </g>
  </g>
  <g id="Group_4853" data-name="Group 4853" transform="translate(30.067 25.967)">
    <g id="Group_4852" data-name="Group 4852">
      <path id="Path_4574" data-name="Path 4574" d="M362.25,304h-9.567a.684.684,0,0,0-.683.683v10.25h1.367v-4.1h8.2v4.1h1.367v-10.25A.684.684,0,0,0,362.25,304Zm-.683,5.467h-8.2v-4.1h8.2Z" transform="translate(-352 -304)" :fill="color"/>
    </g>
  </g>
  <g id="Group_4855" data-name="Group 4855" transform="translate(34.306 28.449)">
    <g id="Group_4854" data-name="Group 4854" transform="translate(0 0)">
      <rect id="Rectangle_2251" data-name="Rectangle 2251" width="3" height="2" transform="translate(-0.307 -0.449)" :fill="color"/>
    </g>
  </g>
  <g id="Group_4857" data-name="Group 4857" transform="translate(32.116 15.717)">
    <g id="Group_4856" data-name="Group 4856">
      <path id="Path_4575" data-name="Path 4575" d="M382.8,188.567l-1.367-4.1a.684.684,0,0,0-.648-.467h-2.733a.683.683,0,0,0-.648.467l-1.367,4.1a.684.684,0,0,0,.648.9h2.05v2.05h-1.367v1.367h4.1v-1.367H380.1v-2.05h2.05a.684.684,0,0,0,.648-.9Zm-5.167-.467.912-2.733h1.749l.911,2.733Z" transform="translate(-375.997 -184)" :fill="color"/>
    </g>
  </g>
  <g id="Group_4859" data-name="Group 4859">
    <g id="Group_4858" data-name="Group 4858">
      <path id="Path_4576" data-name="Path 4576" d="M40.6,8.943,20.779.059a.687.687,0,0,0-.559,0L.4,8.943a.684.684,0,0,0-.4.624v8.2H1.367V10.009L20.5,1.432l19.133,8.577v7.757H41v-8.2A.684.684,0,0,0,40.6,8.943Z" :fill="color"/>
    </g>
  </g>
  <g id="Group_4861" data-name="Group 4861" transform="translate(15.717 5.467)">
    <g id="Group_4860" data-name="Group 4860">
      <path id="Path_4577" data-name="Path 4577" d="M193.5,67.987l-1.348.227a3.366,3.366,0,0,1,.048.57,3.413,3.413,0,1,1-2.277-3.222l.455-1.289a4.784,4.784,0,1,0,3.188,4.511A4.835,4.835,0,0,0,193.5,67.987Z" transform="translate(-184 -64)" :fill="color"/>
    </g>
  </g>
  <g id="Group_4863" data-name="Group 4863" transform="translate(18.65 5.667)">
    <g id="Group_4862" data-name="Group 4862">
      <path id="Path_4578" data-name="Path 4578" d="M224.494,66.344l-4.3,4.3-.884-.884-.966.966,1.367,1.367a.683.683,0,0,0,.966,0l4.783-4.783Z" transform="translate(-218.344 -66.344)" :fill="color"/>
    </g>
  </g>
</svg>

</template>

<script>
export default {
  name: 'IconSelectFac',
  props: {
    width: {
      type: Number,
      default: 41
    },
    height: {
      type: Number,
      default: 41
    },
    color: {
      type: String,
      default: 'var(--color__primary-light)'
    }
  }
}
</script>
