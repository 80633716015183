<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 19.503 17.157">
  <path id="Path_7786" data-name="Path 7786" d="M3445.969,951l-8.751,15.157h17.5Z" transform="translate(-3436.218 -950)" fill="none" :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
</svg>

</template>

<script>
export default {
  name: 'IconClose',
  props: {
    width: {
      type: Number,
      default: 19.503
    },
    height: {
      type: Number,
      default: 17.157
    },
    color: {
      type: String,
      default: '#fdac00'
    }
  }
}
</script>
