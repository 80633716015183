<template>
  <div class="bg-primary-light pt-6 pb-16 px-5">
    <v-form ref="form" lazy-validation>
      <h1 class="--white text-large">{{ $t('title.reservation') }}</h1>
      <v-select
        :value="selectedDates"
        :items="[selectedDates]"
        @click="isVisibleRoomAv = true"
        solo
        flat
        hide-details
        outlined
        height="75"
        class="mt-5 rounded-lg"
      >
        <template v-slot:prepend-inner>
          <IconSelectDate :width="35" :height="35" class="mx-3" />
        </template>
      </v-select>
      <v-select
        :items="roomTypes"
        item-text="name"
        item-value="id"
        v-model="roomTypeId"
        solo
        flat
        hide-details
        outlined
        :height="70"
        class="mt-4 mb-6 rounded-lg"
      >
        <template v-slot:prepend-inner>
          <IconFurniture :width="35" :height="35" class="mx-3" />
        </template>
      </v-select>
      <div v-if="roomsGuestsAvailability.rooms > 0">
        <v-row class="ma-0 mb-6">
          <v-col cols="2" class="pa-0 vertical-child-center">
            <span class="text-title --w-bold --white">{{ $t('common.nRoom') }}</span>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-select
              v-model="numberOfRooms"
              :items="availableRooms"
              solo
              flat
              hide-details
              outlined
              height="65"
              class="select-align-center rounded-lg"
            ></v-select>
          </v-col>
          <v-col
            cols="2"
            class="pa-0 text-left text-title --w-bold --white"
            align-self="end"
          >
            {{ $t('common.room') }}
          </v-col>
        </v-row>
        <div class="px-0 mb-6 mx-1" v-for="(roomNumber, i) in numberOfRooms" :key="i">
          <h2 class="mb-3 text-title --white text-left">
            {{ numberOfRooms === 1 ? '' : $t('common.room') + roomNumber }} {{ $t("common.nGuest") }}
          </h2>
          <RoomGuestsNumber
            :newLineTitle="false"
            titleClass="--white"
            labelClass="--white"
            :maxOccupancy="roomsGuestsAvailability.maxOccupancy"
            :bedShareMaxOccupancy="roomsGuestsAvailability.bedShareMaxOccupancy"
            :numberOfAdults="numberOfGuests[i].adults"
            :numberOfChildren="numberOfGuests[i].children"
            :numberOfBedShare="numberOfGuests[i].bedShare"
            @onNumberOfAdultsChange="v => setNumOfAdults(i, v)"
            @onNumberOfBedShareChange="v => setNumOfBedShare(i, v)"
            @onNumberOfChildrenChange="v => setNumOfChildren(i, v)"
          />
          <v-divider
            v-if="roomNumber !== numberOfRooms"
            color="#fff"
            class="ma-4"
          />
        </div>
      </div>
      <div class="px-0">
        <template v-if="isReadyForPriceTemporaryBooking">
          <h4 class="white--text mt-3">
            <span class="text-m-heading --w-regular">
              {{ $t('common.total') }}
            </span>
            <span v-if="calculating" class="text-max --w-bold mr-4">
              <v-progress-circular indeterminate color="white" />
            </span>
            <span v-else class="text-max --w-bold mr-4">
              {{ point | toThousands }}<span class="text-large">{{ unit }}</span>
            </span>
            <span class="text-large --w-bold">
              {{ night }}{{ $t('common.night') }}
            </span>
            <span class="text-default --w-regular">{{ $t('common.totalOf') }}</span>
          </h4>
          <v-btn
            elevation="0"
            :loading="calculating || checkingAdjuscent"
            :disabled="calculating || hasError || checkingAdjuscent"
            class="mt-5 py-6"
            @click="submitForm"
            block
            rounded
            color="btn-grad--orange"
            x-large
          >
            <span class="text-heading font-weight-medium w-100 --white">{{ $t('buttons.goToReservation') }}</span>
            <IconChevronRightCircle color="white" width="23" height="23" class="mr-2"/>
          </v-btn>
        </template>
        <template v-else-if="roomsGuestsAvailability.rooms === 0 && roomTypeId">
          <v-btn
            elevation="0"
            :loading="calculating"
            :disabled="calculating"
            class="mt-5 py-6"
            @click="isAddWaitListModalOpen = true"
            block
            rounded
            color="btn-grad--orange"
          >
            <span class="w-100 --white text-heading">{{ $t('buttons.addWaitList') }}</span>
            <IconChevronRightCircle color="white" width="23" class="mr-2"/>
          </v-btn>
        </template>
        <template v-else-if="isMaxOccupancyOverflow">
          <h3 class="--white">
            {{ $t("reservationAndUsageHistory.overMaxOccupancy") }}
          </h3>
        </template>
        <template v-else>
          <h3 class="--white">
            {{ $t("reservationAndUsageHistory.enterAccommodationDetails") }}
          </h3>
        </template>
        <AddWaitListModal
          v-if="checkInDate && checkOutDate && facilityInfo && roomType"
          :visible="isAddWaitListModalOpen"
          @close="isAddWaitListModalOpen = false"
          :checkInDate="checkInDate"
          :checkOutDate="checkOutDate"
          :facilityInfo="facilityInfo"
          :roomType="roomType"
        />
      </div>
      <RoomAvailability
        :visible="isVisibleRoomAv"
        :checkIn="checkInDate"
        :checkOut="checkOutDate"
        @close="isVisibleRoomAv = false"
        @confirm="selectDate"
      />
    </v-form>
    <YesNoModal
      :visible="hasAdjuscentBooking"
      yesText="予約変更・予約履歴に移動する"
      noText="このまま新規予約する"
      @yes="goToHistory"
      @no="commitForm"
      @close="hasAdjuscentBooking = false"
    >
      ご登録の予約日程の前後に同施設・同客室の予約があります
    </YesNoModal>
  </div>
</template>

<script>
import IconSelectDate from '@/components/icons/IconSelectDate'
import IconFurniture from '@/components/icons/IconFurniture'
import IconChevronRightCircle from '@/components/icons/IconChevronRightCircle'
import RoomAvailability from '@/views/VacancySearch/components/RoomAvailability'
import { jaShortDate, addDays } from '@/utils/get-date'
import AddWaitListModal from '@/components/Modal/AddWaitList'
import { numberArrayGenerator } from '@/utils/generator'
import RoomGuestsNumber from '@/components/ReservationComponents/RoomGuestsNumber'
import { getHashcodeOfObjects } from '@/utils/string'
import gql from 'graphql-tag'
import YesNoModal from '../Modal/Common/YesNoModal.vue'

export default {
  components: {
    IconSelectDate,
    IconFurniture,
    RoomAvailability,
    AddWaitListModal,
    IconChevronRightCircle,
    RoomGuestsNumber,
    YesNoModal
  },
  created () {
    this.$store.commit('setNumberOfRoomsForBooking', {
      numberOfRooms: 0,
      asDefault: true
    })
  },
  props: {
    selectedRoomTypeId: {
      type: Number
    }
  },
  data () {
    return {
      lastError: null,
      calculateTimeout: null,
      isVisibleRoomAv: false,
      point: null,
      isAddWaitListModalOpen: false,
      roomTypesForPrices: [],
      checkingAdjuscent: false,
      hasAdjuscentBooking: false
    }
  },
  mounted () {
    this.roomTypeId = this.roomTypes[0]?.id
  },
  watch: {
    selectedRoomTypeId () {
      this.roomTypeId = this.selectedRoomTypeId
    },
    roomTypeId (val) {
      if (val !== this.selectedRoomTypeId) {
        this.$emit('changeRoomTypeId', val)
      }
    },
    async shouldCalculatePrice (_) {
      await this.calculatePrice()
    },
    shouldRefetchAvailabilityOfTheDateRange: {
      immediate: true,
      async handler ({
        checkInDate, checkOutDate, facilityId
      }) {
        await this.$doLoading(async () => {
          await this.$showGqlError(async () => {
            const facilityInfo = await this.$store.dispatch('getPricingOfFacility', {
              facilityId,
              fromDate: checkInDate,
              toDate: checkOutDate
            })
            this.roomTypesForPrices = facilityInfo.roomTypes
          })
        })
      }
    },
    roomsGuestsAvailability () {
      this.resetRoomNumber()
    }
  },
  computed: {
    calculating () {
      return !!this.calculateTimeout
    },
    shouldCalculatePrice () {
      return getHashcodeOfObjects(
        this.roomTypeId,
        this.numberOfRooms,
        this.checkInDate,
        this.checkOutDate,
        this.numberOfGuests
      )
    },
    shouldRefetchAvailabilityOfTheDateRange () {
      return {
        checkInDate: this.checkInDate,
        checkOutDate: this.checkOutDate,
        facilityId: this.facilityInfo.id
      }
    },
    selectedDates () {
      const from = jaShortDate(this.$store.state.newBooking.checkInDate)
      const to = jaShortDate(this.$store.state.newBooking.checkOutDate)

      return `${from} - ${to}`
    },
    roomTypes () {
      return this.$store.state.reservationCalendar.roomTypes
    },
    roomsGuestsAvailability () {
      let minAvailableRoom = 0
      if (this.roomType) {
        const available = this.roomType.priceAndAvailability
        const min = available.findIndex(rt => rt.stayDate === this.checkInDate)
        const max = available.findIndex(rt => rt.stayDate === this.lastStayDate)
        const minAvailableRooms = available.slice(min, max + 1).map(a => a.roomsAvailable)
        minAvailableRoom = minAvailableRooms.length ? Math.min(...minAvailableRooms) : 0
      }
      return {
        rooms: minAvailableRoom,
        maxOccupancy: this.roomType?.maxOccupancy ?? 0,
        bedShareMaxOccupancy: this.roomType?.bedShareMaxOccupancy ?? 0
      }
    },
    roomTypeId: {
      get () { return this.$store.state.newBooking.roomTypeId },
      set (val) {
        this.$store.commit('setRoomTypeIdForBooking', val)
        this.resetRoomNumber()
      }
    },
    numberOfRooms: {
      get () { return this.$store.state.newBooking.numberOfRooms },
      set (val) {
        this.$store.commit('setNumberOfRoomsForBooking', {
          numberOfRooms: val,
          asDefault: true
        })
      }
    },
    availableRooms () {
      return numberArrayGenerator(this.roomsGuestsAvailability.rooms)
    },
    night () {
      return this.$store.getters.numberOfStayDays
    },
    numberOfGuests () {
      return this.$store.state.newBooking.numberOfGuests
    },
    isReadyForPriceTemporaryBooking () {
      return this.$store.getters.isReadyForPriceTemporaryBooking && !this.isMaxOccupancyOverflow
    },
    isMaxOccupancyOverflow () {
      return this.numberOfGuests.some(n => n.adults + n.children > this.roomsGuestsAvailability.maxOccupancy)
    },
    unit () {
      return this.$store.getters.newBookingAccommodationPriceUnit()
    },
    roomType () {
      return this.roomTypesForPrices.find(r => r.id === this.roomTypeId)
    },
    facilityInfo () {
      return this.$store.state.newBooking.facilityInfo
    },
    checkInDate () {
      return this.$store.state.newBooking.checkInDate
    },
    checkOutDate () {
      return this.$store.state.newBooking.checkOutDate
    },
    contractId () {
      return this.$store.state.newBooking.contractId
    },
    lastStayDate () {
      return addDays(this.checkOutDate, -1)
    },
    hasError () {
      return !!this.lastError
    }
  },
  methods: {
    async submitForm () {
      if (this.$refs.form.validate()) {
        // TO2020-1236: check the adjuscent bookings
        this.checkingAdjuscent = true
        try {
          this.hasAdjuscentBooking = false
          const bookings = await this.$showGqlError(async () => {
            const data = await this.$apollo
              .query({
                query: gql`
                  query getConnectedBookings(
                    $checkInDate: DateString!
                    $checkOutDate: DateString!
                    $roomTypeId: Int!
                    $contractId: Int!
                  ) {
                    getConnectedBookings(
                      checkInDate: $checkInDate
                      checkOutDate: $checkOutDate
                      roomTypeId: $roomTypeId
                      contractId: $contractId
                    ) {
                      bookings {
                        id
                      }
                    }
                  }
                `,
                variables: {
                  checkInDate: this.checkInDate,
                  checkOutDate: this.checkOutDate,
                  roomTypeId: this.roomTypeId,
                  contractId: this.contractId
                },
                fetchPolicy: 'no-cache'
              })
            return data.data.getConnectedBookings.bookings
          })
          if (bookings.length) {
            this.hasAdjuscentBooking = true
          } else {
            this.commitForm()
          }
        } finally {
          this.checkingAdjuscent = false
        }
      }
    },
    commitForm () {
      this.$emit('commit')
    },
    goToHistory () {
      this.$router.push({ name: 'home-booking' })
    },
    async calculatePriceLogic () {
      this.calculateTimeout = null
      try {
        await this.$showGqlError(async () => {
          const { totalPrice } = await this.$store.dispatch('calculateAccommodationPrice')
          this.point = totalPrice
          this.lastError = null
        }, {
          CREATE_BOOKING_TOO_MANY_GUESTS: () => {}
        })
      } catch (e) {
        this.lastError = e
      }
    },
    async calculatePrice () {
      if (this.roomsGuestsAvailability.rooms === 0 || this.isMaxOccupancyOverflow) {
        this.point = null
      } else if (this.isReadyForPriceTemporaryBooking) {
        clearTimeout(this.calculateTimeout)
        this.calculateTimeout = setTimeout(async () => {
          await this.calculatePriceLogic()
        }, 500)
      }
    },
    selectDate ({ checkInDate, checkOutDate }) {
      this.$store.commit('setCheckInDate', checkInDate)
      this.$store.commit('setCheckOutDate', checkOutDate)
      this.isVisibleRoomAv = false
    },
    setNumOfBedShare (ind, num) {
      this.$store.commit('setNumberOfRoomBedShare', { ind, num })
    },
    setNumOfChildren (ind, num) {
      this.$store.commit('setNumberOfRoomChildren', { ind, num })
    },
    setNumOfAdults (ind, num) {
      this.$store.commit('setNumberOfRoomAdults', { ind, num })
    },
    resetRoomNumber () {
      this.$store.commit('setNumberOfRoomsForBooking', {
        numberOfRooms: this.roomsGuestsAvailability.rooms ? 1 : 0,
        asDefault: false
      })
    }
  }
}
</script>
